<template>
    <div class="image p-mx-auto"></div>
    <h2 class="name text-main p-text-center">{{ teacherInfo.name }}</h2>
    <div class="p-d-flex p-jc-center p-flex-wrap">
        <div
            v-for="(item, index) in teacherInfo.tags"
            :key="`tag-${index}`"
            class="border-main white text-main p-px-3 p-py-2 p-m-2"
        >
            {{ item }}
        </div>
    </div>
    <p class="content p-mx-auto p-mt-6" v-html="teacherInfo.introduction"></p>
    <h2 class="name text-main p-text-center">{{ labInfo.name }}</h2>
    <p class="content p-mx-auto p-mt-6" v-html="labInfo.introduction"></p>
    <div class="video-wrapper p-d-flex p-jc-center p-mt-6 p-mb-5 p-mb-sm-0">
        <iframe width="900" height="500" src="https://www.youtube.com/embed/-3s4s3GlP2Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</template>

<script>
    import { teacherInfo, labInfo } from "@/dummies/labInformaton";

    export default {
        name: 'About',
        data() { return { teacherInfo, labInfo } }
    }
</script>

<style scoped lang="scss">
    .image {
        width: 300px;
        padding-bottom: 356px;
        background: url("/images/teacher.jpg") no-repeat center center/cover;
        box-shadow: 20px 20px $color-main;
    }

    .name {
        font-size: 40px;
        margin-top: 80px;
    }

    .content {
        max-width: 900px;
    }

    .videoWrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
    }
    .videoWrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>